import { render, staticRenderFns } from "./basicSetup.vue?vue&type=template&id=1e58d0dd&scoped=true&"
import script from "./basicSetup.vue?vue&type=script&lang=js&"
export * from "./basicSetup.vue?vue&type=script&lang=js&"
import style0 from "./basicSetup.vue?vue&type=style&index=0&id=1e58d0dd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e58d0dd",
  null
  
)

export default component.exports