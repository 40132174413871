<template>
  <div class="basicSetup">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>分销管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="门槛设置"
        ><el-input v-model="formList.base_fullMoney"></el-input
      ></el-form-item>
      <el-form-item label=""><span>消费多少才可以有分销这个功能</span></el-form-item>
      <el-form-item label="审核设置">
        <el-radio v-model="formList.base_isAudit" :label="1">需要审核</el-radio>
        <el-radio v-model="formList.base_isAudit" :label="0">不需要审核</el-radio>
        <span span style="color: red">有会员申请成为合伙人是否审核</span>
      </el-form-item>
      <!-- <el-form-item label="外部店长提现是否需要审核">
           <el-radio v-model="formList.base_is_out_exam" :label="1">是</el-radio>
            <el-radio v-model="formList.base_is_out_exam" :label="0">否</el-radio>
        </el-form-item> -->
      <el-form-item label="最少提现金额"
        ><el-input v-model="formList.base_mixCashMoney"></el-input
      ></el-form-item>
      <el-form-item label="最高提现金额"
        ><el-input v-model="formList.base_maxCashMoney"></el-input
      ></el-form-item>
      <el-form-item label="每月提现次数"
        ><el-input v-model="formList.base_cashCount"></el-input
      ></el-form-item>
      <el-form-item label=""
        ><span>0为不限次数，1 为1次 2为2次 以此类推....</span></el-form-item
      >
      <el-form-item label="佣金设置">
        <el-radio v-model="formList.isProfit" :label="1">是</el-radio>
        <el-radio v-model="formList.isProfit" :label="0">否</el-radio>
        <span span style="color: red">内部店长当天出游是否给佣金</span>
      </el-form-item>
      <el-form-item label="分销规则">
        <!-- <quill-editor
          class="richEdit"
          :options="quillOption"
          v-model.trim="formList.base_fxRule"
        >
        </quill-editor> -->
        <VueUeditorWrap
          v-model="formList.base_fxRule"
          :config="myConfig"
          ref="myTextEditor"
        ></VueUeditorWrap>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="onSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { distributionBaseSelect, distributionBaseEdit } from "../../api/wanxiaoShop";
import quillConfig from "../../../quill-config";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: "basicSetup",
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 400, // 初始容器高度
        initialFrameWidth: "140%", // 初始容器宽度
        //  serverUrl: "/api/config",
        serverUrl: "/config", //打包
        // UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/", //打包
        maximumWords: "100000000",
      },
      quillOption: quillConfig,

      formList: {
        base_fullMoney: null,
        base_isAudit: null,
        base_mixCashMoney: null,
        base_cashCount: null,
        base_fxRule: null,
        base_maxCashMoney: null,
        isProfit: 0,
        // base_is_out_exam:0
      },
    };
  },
  created() {
    this.getdistributionBaseSelect();
  },
  methods: {
    async onSave() {
      const { data } = await distributionBaseEdit({
        fullMoney: this.formList.base_fullMoney,
        isAudit: this.formList.base_isAudit,
        mixCashMoney: this.formList.base_mixCashMoney,
        cashCount: this.formList.base_cashCount,
        fxRule: this.formList.base_fxRule,
        maxCashMoney: this.formList.base_maxCashMoney,
        isProfit: this.formList.isProfit,
        // outExam:this.formList.base_is_out_exam,
      });
      if (data.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async getdistributionBaseSelect() {
      const { data } = await distributionBaseSelect();
      if (data.code === 0) {
        console.log(data.data);
        this.formList.base_fullMoney = data.data[0].base_fullMoney;
        this.formList.base_isAudit = data.data[0].base_isAudit;
        this.formList.base_mixCashMoney = data.data[0].base_mixCashMoney;
        this.formList.base_cashCount = data.data[0].base_cashCount;
        this.formList.base_fxRule = data.data[0].base_fxRule;
        this.formList.isProfit = data.data[0].base_is_profit || 0;

        this.formList.base_maxCashMoney = data.data[0].base_maxCashMoney;
        this.formList.base_is_out_exam = data.data[0].base_is_out_exam || 0;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.basicSetup {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  /deep/ .el-form {
    margin-top: 50px;
    .el-form-item {
      .el-form-item__label {
        width: 120px !important;
      }
      .el-form-item__content {
        margin-left: 120px !important;
        width: 650px;
        .richEdit {
          height: 650px;
          .ql-container {
            height: 450px;
          }
        }
      }
    }
  }
}
</style>
